/* You can add global styles to this file, and also import other style files */
html, body {
  height: 100%;
}
body {
  background-color: var(--surface-0);
  color: var(--text-color);
  font-family: var(--font-family);
  padding: 0;
  margin: 0;
}

.fr-emoticon-img {
  background-repeat: no-repeat !important;
  width: 1em;
  height: 1em;
  min-height: 20px;
  min-width: 20px;
  display: inline-block;
  line-height: 1;
  vertical-align: middle;
}

.fr-bordered {
  border: solid 5px #CCC;
}

.fr-rounded {
  border-radius: 10px;
}

.fr-shadow {
  box-shadow: 10px 10px 5px 0 #cccccc;
}

.fr-text-gray {
  color: #AAA;
}

.fr-text-bordered {
  border-top: solid 1px #222;
  border-bottom: solid 1px #222;
  padding: 10px 0;
}

.fr-text-spaced {
  letter-spacing: 1px;
}

.fr-text-uppercase {
  text-transform: uppercase;
}

.required {
  color: red;
}

.p-progress-spinner-circle {
  stroke: #4CAF50 !important;
}

.vertical-align-top {
  vertical-align: top;
}

article::-webkit-scrollbar
{
  height: 9px;
  width: 9px;
}

article::-webkit-scrollbar-track
{
  border-radius: var(--border-radius);
  background-color: var(--surface-c);
}

article::-webkit-scrollbar-thumb
{
  border-radius: var(--border-radius);
  background-color: var(--surface-d);

  &:hover {
    background-color: var(--surface-hover);
  }
}

.overflow-wrap-anywhere {
  overflow-wrap: anywhere;
}

.border-round-6px {
  border-radius: 6px !important;
}
@media screen and (min-width: 576px) {
  .sm\:border-round-6px {
    border-radius: 6px !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:border-round-6px {
    border-radius: 6px !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:border-round-6px {
    border-radius: 6px !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:border-round-6px {
    border-radius: 6px !important;
  }
}
.p-radiobutton .p-radiobutton-box{
    border: .1rem solid var(--gray-200);
}
app-draggable-card .h-3rem{
    min-height: 3rem !important;
    height: auto !important;
}
app-draggable-card .text-overflow-ellipsis{
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    white-space: normal !important;
}

.p-field-checkbox .answer-label{
    width: 100% !important;
    user-select: none;
}
.option-group:not(.tiled) .p-field-checkbox .answer-label > div:first-of-type{
    padding-left: .5rem;
}
.option-group:not(.tiled) .p-field-checkbox .answer-label > div:last-of-type{
    padding-left: .5rem;
    flex-grow: 1;
}
p-checkbox[name='isSkipButtonEnabled'] .p-component{
    margin: 0.5rem .5rem .5rem 1rem;
}

.p-radiobutton, .p-radiobutton .p-radiobutton-box {
    width: 1.285rem;
    height: 1.285rem;
    line-height: 1.285rem;
}
.p-radiobutton .p-radiobutton-box .p-radiobutton-icon{
    width: .6rem;
    height: .6rem;
}
app-select-custom-answers .p-checkbox-label{
    padding-left: 1rem;
}
app-select-custom-answers .p-radiobutton-label{
    padding-left: .5rem;
    margin-left: 0 !important;
}
p-checkbox[name='isSkipButtonEnabled']{
    padding: .5rem 0;
}
app-kano-feature .p-field-checkbox
{
    padding: .5rem 1rem !important;
    .ml-2{
        margin-left: 1rem !important;
    }
}
.p-button.p-button-icon-only{
    padding: 0 !important;
}

label[for='isSkipButtonEnabled']{
    padding-left: 1rem;
}
.grid:not(:has(p-card)) {
    gap: 1vh !important;
}
.grid.tiled {
    justify-content: space-evenly
}

.grid:not(:has(p-card)) .col-6 {
    width: calc(50% - .5vh)
}

.grid:not(:has(p-card)) .col-4 {
    width: calc(33.3333333333% - .75vh)
}

.grid:not(:has(p-card)) .col-3 {
    width: calc(25% - .8vh)
}

@media screen and (max-width: 768px) {
    .grid:not(:has(p-card)) .p-field-checkbox {
        width:100%
    }
}

app-page > div:empty{
    display: none;
}
app-page > div.md\:py-6 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
}
.pb-4 {
    padding-bottom: .5rem !important;
}
.md\:mt-8 {
    margin-top: 2rem !important;
}

p-autocomplete[field="phoneCode"] .p-inputtext{
    width: 3rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
p-autocomplete[field="phoneCode"] .p-autocomplete-dropdown{
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

app-scale:not(:has(app-scale-sliders,app-scale-emotions)) .p-datatable-tbody>tr>td:has(span>span:empty){
    display: none !important;
}